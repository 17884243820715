import React, { Component }  from 'react';

import videojs from "video.js";

class VideoController extends Component {

    componentDidMount() {
        const player = videojs('banner-video-desktop');
        player.play();

        this.videoplayer = videojs('banner-video-desktop-popup');

        this.video = document.querySelector('.js-video-popup');
        this.videoPlayBtn = document.querySelector('.js-play-fullscreen');

        this.customMouse();
        this.headerVideo();
    }

    render() {
        return (
            <video id="banner-video-desktop" className="video-js js-video" poster=""
                   playsInline autoPlay loop preload="metadata" muted>
                <source src="https://player.vimeo.com/external/502969001.hd.mp4?s=b25b5375cf611e29bf26206727559831754f42a8&profile_id=175" type="video/mp4" />
            </video>
        );
    }

    customMouse() {

        // set the variables
        var timer;
        var mouseX = 0, mouseY = 0;
        var xp = 0, yp =0;
        var circle = document.querySelector("#circle");

        function mouseStopped(){
            // if mouse stop moving remove class moving
            // it will hide the circle with opacity transition
            circle.classList.remove('moving');
        }

        // $(document).mousemove(function(e){
        //
        // });

        document.querySelector('.page-top').addEventListener('mousemove', e => {
            // console.log('customMouse');
            // if (isDrawing === true) {
            //     drawLine(context, x, y, e.offsetX, e.offsetY);
            //     x = e.offsetX;
            //     y = e.offsetY;
            // }

            // if mouse start moving add class moving
            // it will show the circle with opacity transition
            circle.classList.add('moving');
            // get the mouse position minus 160px to center the circle
            mouseX = e.pageX - 160;
            mouseY = e.pageY - 160;
            // if mouse stop moving clear timer and call mouseStopped function
            clearTimeout(timer);
            timer=setTimeout(mouseStopped,3000);
        });

        // set the momentum with setInterval function
        setInterval(function(){
            // change 12 to alter damping higher is slower
            xp += ((mouseX - xp)/6);
            yp += ((mouseY - yp)/6);
            // circle.css({left: xp +'px', top: yp +'px'});  //
            circle.style.left =  xp +'px';
            circle.style.top =  yp +'px';
            // circle.css({left: xp +'px', top: yp +'px'});  //
        }, 30);
    }

    headerVideo() {

        if (this.video === undefined || this.video === null ) {
            return;
        }

        if(this.video.length === 0) {
            return;
        }

        this.videoPlayBtn.addEventListener('click', (ev) => {
            ev.preventDefault();
            this.videoplayer.requestFullscreen();
        });

        this.videoplayer.on('fullscreenchange', function () {
            setTimeout(()=>{
                if (this.isFullscreen_) {
                    this.addClass('is-visible');
                    this.play();
                } else {
                    this.pause();
                    this.removeClass('is-visible');
                }
            }, 1);
        });
    }

}

export default VideoController;
